import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/HeaderFooter/Layout"
import ErrorSvg from "../images/assets/error-page-not-found.svg"
import ContentHeader from "../components/404/ContentHeader"
import { Helmet } from "react-helmet"

const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 42px 0;
  svg {
    height: 200px;

    @media (max-width: 1023px) {
      height: 150px;
    }
  }
`
const ContentContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 4%;

  @media (max-width: 1023px) {
    text-align: center;
  }
`

const HomeBtn = styled.button`
  display: inline-block;
  padding: 0.35em;
  width: 250px;
  border: 2px solid var(--cool-black);
  margin: 20px 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: var(--cool-black);
  font-size: 18px;
  text-decoration: uppercase;
  background-color: #00000000;
  text-align: center;
  transition: all 0.3s;
  &:hover {
    background-color: var(--warm-orange);
    border: 2px solid var(--warm-orange);
    color: var(--white);
    cursor: pointer;
  }
`

export default function NotFound() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 Page Not Found</title>
        <meta
          name="description"
          content="404 page not found. Sorry but the page you're looking for could not be found."
        />
      </Helmet>
      <ContentHeader />
      <SvgContainer>
        <ErrorSvg />
      </SvgContainer>
      <ContentContainer>
        <h1>Oops! We couldn't find the page you were looking for.</h1>
        <h3>Page Not Found!</h3>
        <p>Oops! We can't seem to find the page you're looking for.</p>
        <Link to="/">
          <HomeBtn>Go Home</HomeBtn>
        </Link>
      </ContentContainer>
    </Layout>
  )
}
